.application-card {
  background: #F9FAFB;
  box-shadow: var(--sdds-card-box-pressed);
  border-radius: 4px;
  padding: 16px;
  height: 100%;
  

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
  }

  &__thumbnail {
    color: var(--sdds-grey-600);
  }

  &__icon {
    font-size: 28px;
    color: var(--sdds-grey-600);
  }

  &__starmark {
    background-color: transparent;
    border: 0;
    font-size: 18px;
    padding: 0;
    color: var(--sdds-grey-600);
  }

  &__content {
    display: block;
  }

  &__title {
    margin-top: 0;
    font-size: 14px;
    line-height: 16px;
    color: var(--sdds-grey-900);
  }

  &__subtitle {
    font-size: 14px;
    line-height: 16px;
    margin-top: 4px;
    color: var(--sdds-grey-900);
  }

  &__desc {
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 16px;
    color: var(--sdds-grey-700);
  }
}
