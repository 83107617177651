.reflex-search-no-results {
  text-align: left;
  margin: 20px auto 0;

  &__text,
  &__query {
    color: var(--sdds-negative);
    line-height: 20px;
    font-size: 14px;
    margin-bottom: 25px;
  }

  &__query {
    color: var(--sdds-blue-500);
  }

  &__box {
    margin-bottom: 25px;
  }

  &__feedback {
    & p {
      margin-bottom: 0;
    }
  }

  &__box,
  &__feedback {
    max-width: 700px;
    padding: 16px 20px;
    background-color: var(--sdds-grey-50);
    border-radius: 4px;
    border-left: 4px solid var(--sdds-information);
  }

  & ul {
    padding: 4px 21px 0;
    margin: 0;
  }

  & .sdds-headline-07 {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: var(--sdds-grey-958);
  }

  & .sdds-detail-03 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: var(--sdds-grey-958);

    & a,
    & a:visited {
      color: var(--sdds-link);
    }

    & a:hover {
      color: var(--sdds-link-hover);
    }
  }
}
