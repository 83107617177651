.access-tool {
  max-width: 1000px;

  &-group{
    border: 1px solid #eeeff0;
    margin-bottom: 20px;

    &__title {
      background-color: #eeeff0;
      padding: 8px 20px;
      font-family: "Scania sans condensed bold";
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-info {
      
      &__list {
        list-style-type: none;
        padding: 0;
        margin: 0;

        & > li {
          padding: 10px;
          border-bottom: 1px solid #eeeff0;
        }
      }
      &__btn {
        color: #2d2926;
        background-color: transparent;
        border: 1px solid #2d2926;
        border-radius: 50%;
        padding-top: 3px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        transition: background-color 200ms ease-in;

        &:hover {
          background-color: #2d2926;
          color: #fff;
        }
      }
    }

    &__list {
      list-style: none;
      margin: 0px;
      padding: 0px;
    }

    &__item {
      padding: 25px;
      border-bottom: 1px solid #eeeff0;

      &:last-child {
        border-bottom: 0px;
      }
    }

    &__remove {
      float: right;
      border: 0px;
      background-color: transparent;
      padding: 0px;
      float: right;

      i {
        font-size: 20px;
      }
    }

    &__loading {
      justify-content: center;
      align-items: center;
      display: flex;
      margin-bottom: 20px;
      
      .spinner-border {
        width: 44px;
        height: 44px;
      }
    }
  }

  &-form {
    margin-bottom: 20px;

    .reflex-select__container {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    &__group {
      margin-bottom: 10px;
      max-width: 540px;
      .btn {
        padding: 14px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: $medium-grey;
        background-color: $scania-white;
        width: 100%;
        text-transform: none;
        justify-content: unset;
        &:active {
          color: $black !important;
          background-color: $scania-white !important;
          border-color: $scania-light-grey  !important;
          i {
            color: $black !important;
          }
        }
        i {
          font-size: 26px;
          position: absolute;
          right: 0px;
          top: 0px;
          bottom: 0px;
          background: $white;
          border: 1px rgb(200, 201, 199) solid;
          width: 50px;
          margin-right: 0px;

          &:before {
            top: calc(50% - 13px);
            position: relative;
          }
        }
      }

      .dropdown-menu {
        margin-top: 0px;
      }
    }
  }
}
