.typeahead-search {
  &__container {
    width: 100%;
    max-width: 540px;
  }

  &__control {
    border-radius: 0;
    background-color: $scania-white;
    border-color: $scania-light-grey;
    height: 50px;
    box-shadow: none;
    &:hover {
      border-color: $scania-light-grey;
    }
    &--is-focused {
      border-color: $scania-light-grey;
    }

    &--menu-is-open {
      .typeahead-search__dropdown-indicator {
        transform: rotate(180deg);
      }
    }
  }

  &__value-container {
    padding-left: 25px;
  }

  &__placeholder {
    color: $scania-black;
    font-size: 15px;
  }

  &__input {
    font-size: 15px;
  }

  &__multi-value {
    background-color: $medium-grey;
    color: #fff;
    height: 25px;
    display: flex;
    align-items: center;

    &__label {
      color: #fff;
      letter-spacing: 0.11px;
      font-size: 11px;
      font-family: "Scania Sans Condensed";
      padding: 0 9px;
    }

    &__remove {
      align-self: stretch;
      cursor: pointer;
      &:hover {
        color: #fff;
        background-color: $medium-grey;
      }
    }
  }

  &__clear-indicator {
    display: none;
  }

  &__indicator-separator {
    margin: 0;
    background-color: $scania-light-grey;
  }

  &__dropdown-indicator {
    background-color: #fff;
    color: $scania-black;
    font-size: 26px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: $scania-black;
    }
  }

  &__menu {
    border-radius: 0;
    box-shadow: none;
    border: 1px solid $silver;
    margin-top: 0;
    
    &-list {
      padding: 0;
    }
  }

  &__option {
    height: 45px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;

    &:nth-child(odd) {
      background: $scania-white;
    }

    &:hover, &--is-focused {
      background: $silver;
    }
  }
}
