.top-header{

  &__back-button.btn {
    color: $scania-white;
    font-family: $font-scania-sans;
    padding-left: 24px;

    @include media-breakpoint-down(md) {
      color: $scania-black;
      font-size: 12px;
      position: absolute;
    }

    i {
      color: $scania-white;
      font-size: 22px;

      @include media-breakpoint-down(md) {
        color: $scania-black;
        font-size: 18px;
      }
    }
  }

  &--search {
    height: 65px;

    @include media-breakpoint-down(md) {
      height: 72px;
    }

    .top-header__desktop-wrapper {
      justify-content: flex-end;
    }

    .top-header__logo {
      @include media-breakpoint-down(md) {
        margin-left: auto;
        margin-right: auto
      }
    }
  }
}