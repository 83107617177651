.orgindex {
	background-color: white;
	@include media-breakpoint-down(md) {
		padding-top: 20px;
	}

	&-descirption {
		white-space: pre-line;
	}

	&__manager-title--no-margin-top {
		margin-top: 0;
	}

	.profile {
		padding: 0;

		a {
			padding: 25px 15px 20px;
			display: block;
			height: 220px;
			border: 1px solid #EEEFF0;
			background-color: #FAFAFA;

			.thumbnail {
				height: 54px;
				width: 54px;
				-o-object-fit: cover;
				object-fit: cover;
				font-family: "object-fit: cover";
				-ms-flex-negative: 0;
				flex-shrink: 0;
				border-radius: 50%;
				border: 2.7px solid #EEEFF0;
				margin-bottom: 10px;
				background-color: #fff;
			}

			.content {
				width: 100%;

				.title-wrapper {
					height: 42px;
					width: 100%;

					.subtitle {
						font-family: "Scania Sans";
						color: #041E42;
						font-size: 15px;
						line-height: 1.45;
					}
				}

				.card-text {
					font-size: 15px;
					line-height: 19px;

					.company {
						font-size: 11px;
						font-weight: normal;
						line-height: 1.55;
						letter-spacing: 0.1px;
						color: #2D2926;
					}

					.location {
						font-size: 11px;
						font-weight: normal;
						line-height: 1.55;
						letter-spacing: 0.1px;
						color: #2D2926;
					}
				}
			}
		}
	}
	.navigation-list {
		@include media-breakpoint-down(md) {
			display: none;
		}

		& .nav-link.nav-item-active {

				&:hover {
					text-decoration: none !important;
				}
				> span {
					border-bottom: 3px solid $orange;
				}
		}
	}
	h4 {
			margin: 0;
	}
	.breadcrumb {
		margin-bottom: 15px;
	}

	.highlight-box-information {
		position: relative;
		padding-right: 50px;
    background-color: $scania-blue;

		h2, a {
			color: $white;
		}

		h2::before {
			flex-shrink: 0;
			text-align: center;
			width: 50px;
			flex-basis: 50px;
			align-self: center;
			margin-right: 10px;
			font-family: 'Font Awesome 5 Pro';
			font-weight: 300;
			font-size: 24px;
			line-height: 16px;
			color: $white;
      content: '\f05a';
		}
	}
}