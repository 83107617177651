.search-contact-card {
  background-color: var(--sdds-grey-50);
  border-radius: 4px;
  box-shadow: var(--sdds-card-box-pressed);
  padding: 16px;
  height: 100%;

  &-header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
  }

  &-thumbnail {
    height: 48px;
    width: 48px;
    min-width: 48px;
    @include object-fit(cover);
    flex-shrink: 0;
    border-radius: 50%;
    border: 1px solid var(--sdds-grey-600);
    background-color: var(--sdds-white);

    &.fallback-profile {
      display: flex;
      justify-content: center;
      align-items: center;

      & span.reflex-sdds-icon {
        width: 23px;
        height: 23px;
        background-color: #96999B;
      }
    }

    &.fallback {
      i {
        background-color: var(--sdds-white);
        color: var(--sdds-grey-600);
        font-size: 22px;
        width: 48px;
        height: 48px;
        border: 1px solid var(--sdds-grey-600);
        border-radius: 50%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &-headline,
  &-sub__headline {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    word-break: break-word;
  }

  &-headline {
    font-size: 14px;
    line-height: 16px;
    margin: 0;

    & > a,
    & > a:visited {
      color: var(--sdds-grey-900);
    }

    &:hover {
      & > a {
        text-decoration: underline;
      }
    }
  }

  &-sub__headline {
    font-size: 14px;
    line-height: 16px;
    color: var(--sdds-grey-700);
    margin: 0;
  }

  &-avatar {
    margin-right: 12px;

    .icon-profile,
    .icon-organisation {
      color: var(--sdds-grey-600);
      font-size: 20px;
      width: 48px;
      height: 48px;
      background-color: var(--sdds-white);
      border: 1px solid var(--sdds-grey-600);
      border-radius: 50%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &-divider {
    margin: 0;
    border-top: 1px solid var(--sdds-grey-300);
  }

  &-footer {
    margin-top: 10px;
  }

  &-company,
  &-location,
  &-contact  {
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    color: var(--sdds-grey-700);
  }

  &-company {
    margin-bottom: 4px;

    @include fontAwesome(sitemap) {
      color: var(--sdds-grey-600);
      font-size: 10px;
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }
  }

  &-location {
    @include fontAwesome(map-marker-alt) {
      color: var(--sdds-grey-600);
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }
  }
}
