.dashboard {
  &-wrapper {
    .dashboard-collapsible-box {
      margin-bottom: 5px;
    }

    .dashboard-select {
      margin-bottom: 15px;
    }

    .collapsible-bold {
      font-weight: bold;
    }

    a {
      word-break: break-all;
    }

    .collapsible-area {
      padding: 0;

      & > div {
        padding: 25px 25px 30px;
      }
    }
  }

  &-filter__label {
    font-size: 12px;
    font-weight: bold;
    color: $scania-black;
    margin-bottom: 5px;
  }

  &-subscriber__info > div {
    display: flex;
    margin-bottom: 5px;

    @media screen and (max-width: 480px) {
      flex-direction: column;
    }
    
    & > dd {
      margin-left: 4px;
      margin-bottom: 0;

      @media screen and (max-width: 480px) {
        margin-left: 0;
        margin-top: 4px;
      }
    }
  }

  &__loading {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 20px;
    
    .spinner-border {
      width: 44px;
      height: 44px;
    }
  }

  &-hits {
    margin-bottom: 15px;

    & > span {
      margin-left: 3px;
    }
  }
}
