.reflex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70vh;


}


.my-news {
  .news-promotion {
    &-title {
      background-color: $silver;
      font-family: $font-family-condensed;
      padding: 8px 20px;
      margin-bottom: 10px;
    }

    &-hero {
      flex-direction: column;
      height: 360px;
      @include media-breakpoint-down(lg) {
        height: auto;
      }
      .card-image-container {
        min-width: 100%;
      }
      .card-body {
        padding: 15px 25px 25px;
      }
    }
    .card {
      @include media-breakpoint-down(lg) {
        height: auto;
      }

      &-breadcrumb {
        min-height: 12px;
      }
    }
    .col-lg-4.col-md-6, .col-lg-12.col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
