.profile-footer {
  margin-top: 80px;
  padding: 10px 20px;
  background-color: $silver;
  font-size: 13px;
  line-height: 13px;
  font-family: $font-family-base;
  &-avatar  {
    max-width: 54px;
    padding-right: 0;
    float: left;

    &__img {
      flex-shrink: 0;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      @include object-fit(cover);
      border: 2px solid $scania-light-grey;

      &.fallback-profile {
        display: flex;
        justify-content: center;
        align-items: center;
        span.reflex-sdds-icon {
          width: 18px;
          height: 18px;
          background-color: $medium-grey;
        }
      }
    }
  }
  &-content {
    & .col {
      padding-left: 0;
      @include media-breakpoint-down(xs) {
        display:flex;

        & span {
          padding-right: 6px;
          margin-bottom: 0;
        }

        &:first-child {
          padding-bottom: 10px;
        }
      }
    }
    @include media-breakpoint-down(xs) {
      overflow: hidden;
      display:block;
    }
    display: flex;
  }

  & span {
    font-family: $font-family-condensed;
    font-weight: 700;
    display: block;
    margin-bottom: 8px;
  }

  &__placeholder {
    &-sm-up {
      display: block;
    }
    &-sm-down {
      display: none;
    }
  }
  @include media-breakpoint-down(xs) {
    padding: 13px 20px 12px;
    &__placeholder {
      &-sm-up {
        display: none;
      }
      &-sm-down {
        display: block;
      }

    }
  }
  &__placeholder {
    height: 50px;
    svg {
      max-width: 800px;
    }
  }

}
