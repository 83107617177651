.search-enterprise-block,
.search-enterprise-block-reflex,
.search-enterprise-block-sharepoint,
.search-enterprise-block-contact,
.search-enterprise-block-application {
  margin-top: 24px;
  padding-left: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: var(--sdds-grey-50);
  border-radius: 4px;
  overflow: hidden;

  @include media-breakpoint-down(xs) {
    margin: 0 -15px;
  }

  & + .search-enterprise__hit {
    border-top: 0 !important;
  }

  & > h2.sdds-headline-05 {
    font-size: 16px;
    line-height: 24px;
    color: var(--sdds-grey-958);
    margin: 0 0 8px 0;
  }

  &__slider {
    position: relative;
    margin-right: 15px;

    &--next,
    &--prev {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 48px;
      height: 48px;
      background-color: var(--sdds-grey-100);
      border: 0;
      z-index: 10;
      box-shadow:0 3px 3px rgb(0 0 0 / 15%) ;

      & span.reflex-sdds-icon {
        width: 20px;
        height: 20px;
        background-color: #000;
        display: block;
      }

      &:hover {
        background-color: var(--sdds-grey-200);
      }
    }
    
    &--next {
      right: -14px;
    }

    &--prev {
      left: -16px;
    }

    .slick-list {
      margin: 0 -8px;

      .slick-track {
        display: flex;
        margin-bottom: 16px;
        height: 100%;

        .slick-slide {
          margin: 0 8px;

          & > div {
            height: 100%;
          }

          & .search-contact-card {
            background-color: var(--sdds-white);
          }

          & .application-card {
            background-color: var(--sdds-white);
          }

          & .reflex-card {
            background-color: var(--sdds-white);
          }

          & .sharepoint-card {
            background-color: var(--sdds-white);
          }
        }
      }
    }
  }

  &__load--more {
    padding: 4px 8px;
    background-color: var(--sdds-blue-600);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    color: var(--sdds-white);
    font-size: 12px;
    line-height: 16px;

    &:visited {
      color: var(--sdds-white);
    }

    &:hover {
      text-decoration: none;
      color: var(--sdds-white);
      background-color: var(--sdds-blue-500);
    }
  }
}

