$breadcrumb-color: #56657A;

.reflex-search-breadcrumbs {
  margin-bottom: 4px;

  ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: initial;

    & li.sdds-detail-05 { 
      font-size: 12px;
      line-height: 16px;
      color: $breadcrumb-color;

      & a {
        color: $breadcrumb-color;
      }

      &:first-child a {
        font-weight: bold;
      }

      & a:visited {
        color: $breadcrumb-color;
      }

      span {
        padding: 0 3px;
      }

      &:last-child span {
        display: none;
      }
    }
  }
}
