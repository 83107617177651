$hit-margin: 1rem;

.reflex-search {
  margin-bottom: 30px;

  .input-group.search .form-control {
    border: 1px solid $scania-light-grey;
  }

  &__search {
    margin-bottom: $hit-margin;
    flex-direction: column;

    .form-group {
      width: 100%;
      max-width: 785px;

      label {
        font-size: 12px;
        font-weight: bold;
      }
    }

    .input-group {
      height: 55px;
      max-width: 785px;
    }

    &-button.btn {
      height: 55px;
      width: 60px;
      background-color: $silver;

      i {
        color: $scania-black;
        margin-right: 0;
      }

      &:hover i {
        color: #fff;
      }
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  &__loading {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 250px;
    .spinner-border {
      width: 44px;
      height: 44px;
    }
  }

  &__load-more {
    margin-top: 24px;
    margin-bottom: 32px;
    padding: 16px;
    background-color: var(--sdds-blue-600);
    border: 0;
    border-radius: 4px;
    color: var(--sdds-white);
    font-size: 14px;
    font-family: var(--sdds-font-family-semi-condensed);
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.01em;
    cursor: pointer;

    &:hover {
      background-color: var(--sdds-blue-500);
    }
  }

  &-date {
    font-size: 12px;
    line-height: 8px;
    color: var(--sdds-grey-700);
    margin-bottom: 4px;
  }
}
