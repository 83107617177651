.cookie {
  padding: 25px 30px;
  background-color: $silver;
  display: flex;
  flex-direction: row;
  min-height: 95px;
  border-top: 5px solid $scania-light-grey;
  justify-content: center;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 110;

  &-text {
    max-width: 700px;
    font-size: 15px;
  }

  & .btn-group {
    padding-left: 30px;
    display: block;

    @include media-breakpoint-down(sm) {
      display: block;
      text-align: left;
      margin-top: 15px;
      padding-left: 0;
    }
  }

  &>div {
    text-align: center;

    @include media-breakpoint-down(sm) {
      text-align: left;
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}
