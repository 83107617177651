.sharepoint-card {
  position: relative;
  background: #F9FAFB;
  box-shadow: var(--sdds-card-box-pressed);
  border-radius: 4px;
  padding: 16px 16px 32px;
  height: 100%;
  

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  &__thumbnail {
    color: var(--sdds-grey-600);
  }

  &__icon {
    font-size: 22px;
    color: var(--sdds-grey-600);

    & i::before {
      margin-right: 5px;
    }
  }

  &__content {
    display: block;
  }

  &__heading {
    display: flex;
    margin-bottom: 10px;
  }

  &__title {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: block;
    display: -webkit-box;
    display: flex;
    overflow: hidden;
    margin-top: 3px;
    font-size: 14px;
    line-height: 16px;
    color: var(--sdds-grey-900);
  }

  .reflex-sdds-icon {
    width: 22px;
    height: 22px;
    background-color: black;
    display: block;
    margin-right: 8px;
  }

  &__breadcrumbs {
    font-size: 12px;

    a:first-child {
      margin-right: 3px;
    }

    a:last-child {
      margin-left: 3px;
    }
  }

  &__breadcrumb {
    color: var(--sdds-grey-700);

    &:hover {
      color: var(--sdds-grey-700);
    }

    &:visited {
      color: var(--sdds-grey-700);
    }
  }

  &__desc {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: block;
    display: -webkit-box;
    overflow: hidden;
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 16px;
    color: var(--sdds-grey-900);
  }

  &__date {
    position: absolute;
    bottom: 16px;
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    color: var(--sdds-grey-900);
  }
}
