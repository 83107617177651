$wrapper-shadow: 0px 1px 0px #DBDEE2;

.reflex-search-facets {
  position: relative;
  opacity: 0;
  transition: opacity 200ms ease-in;

  & .sdds-container {
    padding: 0;
    width: 900px;
    position: relative;

    @include media-breakpoint-down(sm) {
      padding: 0 16px;
    }
  }

  &__chevron {
    position: absolute;
    top: -33px;
    right: 55px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-bottom: 10px solid #DBDEE2;
    border-right: 10px solid transparent;
    z-index: 10;

    &--inner {
      position: absolute;
      top: 2px;
      left: -9px;
      border-left: 9px solid transparent;
      border-bottom: 9px solid var(--sdds-grey-50);
      border-right: 9px solid transparent;
    }
  }

  &--has-filters {
    opacity: 1;
    background-color: var(--sdds-grey-50);
    padding: 16px 0 6px;
    box-shadow: $wrapper-shadow;
  }

  &--open {
    opacity: 1;
    background-color: var(--sdds-grey-50);
    padding: 22px 0 12px;
    box-shadow: $wrapper-shadow;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  &__title {
    background-color: $silver;
    display: flex;
    align-items: center;
    padding: 10px 15px;

    i {
      font-size: 17px;
      color: $scania-blue;
    }

    h3 {
      margin: 0;
      margin-left: 8px;
      font-size: 16px;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .reflex-search-facets__item {
    flex: 0 0 33%;
    margin-bottom: 16px;

    @include media-breakpoint-down(md) {
      flex: 0 0 100%;
    }

    &:nth-child(3n+2) {
      padding: 0 10px;

      @include media-breakpoint-down(md) {
        padding: 0;
      }
    }

    &--name {
      font-size: 12px;
      line-height: 16px;
      color: var(--sdds-grey-958);
      margin-bottom: 8px;
    }

    &--remove {
      font-family: var(--sdds-font-family-semi-condensed);
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.01em;
      color: var(--sdds-grey-958);
      border: 0;
      border-radius: 16px;
      background-color: var(--sdds-grey-200);
      padding: 4px 16px;
      display: flex;
      align-items: center;
      transition: background-color 200ms ease-in;
      max-height: 24px;
      margin-bottom: 10px;
      margin-right: 10px;

      & > i {
        width: 16px;
        margin-left: 12px;
        justify-content: center;
        display: flex;
        font-size: 20px;
      }

      &:hover {
        background-color: var(--sdds-grey-400);
      }
    }
  }

  @include media-breakpoint-down(md) {
    .reflex-search-facets {
      padding: 20px;
    }
  }


  .reflex-select__option--is-disabled {
    color: var(--sdds-grey-400);
    cursor: not-allowed;
  }
  .reflex-sdds-select__menu {
    z-index: 2;
  }
}
