a.sdds-chip,
button.sdds-chip {
  color: var(--sdds-chips-color);
  background-color: var(--sdds-chips-background);
  cursor: pointer;

  &:hover {
    text-decoration: none;
    background-color: var(--sdds-chips-background-hover);
  }
}

.sdds-chip {
  cursor: unset;
  align-items: center;

  &-icon-right,
  &-icon-left {
    padding-left: 16px;
    padding-right: 16px;
  }

  &-icon-right i {
    margin-left: 10px;
  }

  &-icon-left i {
    margin-right: 10px;
  }

  i {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    font-size: 16px;
    color: var(--sdds-chips-icon-fill);
  }

  &:hover {
    background-color: var(--sdds-chips-background);
  }
}
