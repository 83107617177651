.headroom {
  z-index: 1;

  .last-banner {
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  }

  &-wrapper {
    // position: fixed;
    // z-index: 1050;
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 0 !important; /* Overwrite inline style */
    height: 0;
    // margin: 0 0 67px 0;
    margin: 0;
  }

  &--unfixed, &--scrolled, &--unpinned, &--pinned {
    top: 0;
    left: 0;
    right: 0;
    z-index: 1050;
  }

  &--unfixed {
    position: relative;
  }

  &--scrolled {
    transition: transform 200ms ease-in-out;
  }

  &--unpinned {
    position: fixed;
    transform: translateY(-100%);
  }

  &--pinned {
    position: fixed;
    transform: translateY(0%);
  }

  &-disable-animation {
    position: fixed;
  }
}

.top-header {
  width: 100%;
  background-color: $scania-blue;
  border-bottom: 2px solid $scania-white;
  z-index: 1050;
  @include media-breakpoint-down(md) {
    box-shadow: none;
    background-color: $scania-white;
    height: 72px;
    border-bottom: 2px solid $silver; /* fix color value */
  }

  > ul.nav {
    align-items: center;
    background: none;
    border: none;
    width: 100%;
    height: 65px;
    font-family: $font-family-base;
    @include media-breakpoint-down(md) {
      height: 70px;
      justify-content: space-between;
      background-color: $scania-white;
    }
  }
  button.top-header__hamburger.btn-primary {
    min-width: auto;
    position: relative;
    width: 80px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    border: none;
    padding: 0;
    svg {
      fill: $scania-white; /* TODO: fix value */
      line {
        stroke: $scania-white; /* TODO: fix value */
      }
    }

    &:hover {
      background-color: $scania-blue;
    }
    &:active {
      background-color: $scania-blue;
    }
    &--toggled {
      z-index: $zindex-modal;
      background: transparent;
      box-shadow: none;
      &:hover, &:focus {
        background: transparent;
        box-shadow: none;
      }
    }

    @include media-breakpoint-down(md) {
      order: 3;
      box-shadow: none;
      background-color: $scania-white;
      height: 70px;
      border: none;
      border-left: 1px solid $scania-light-grey; /* fix color value */
      svg {
        fill: $medium-grey;
        line {
          stroke: $medium-grey;
        }
      }
      &:hover, &:focus {
        background-color: $scania-white;
        box-shadow: none;
      }
    }
  }
  button.top-header__hamburger--toggled.btn-primary {
    @include media-breakpoint-down(md) {
      background-color: $medium-grey;
      svg {
        fill: #fff;
        line {
          stroke: #fff;
        }
      }
      &:hover, &:focus, &:active {
        border-color: $scania-light-grey; /* fix color value */
        background-color: $dark-grey;
        color: #fff;
      }
    }
  }

  &__desktop-wrapper {
    display: flex;
    flex-grow: 1;
    align-items: center;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__menu-overlay {
    position: fixed;
    border: none;
    z-index: 1040; /* TODO: use bootstrap variable $zindex-modal-backdrop; */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0.5;
    visibility: visible;
    background-color: rgb(0, 0, 0);
    transition: opacity 0.075s ease-in-out, visibility 0.075s ease-in-out; /* TODO: mixin? */

    &--hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__desktop-menu {
    position: absolute;
    z-index: 1050; /* TODO: use bootstrap variable $zindex-modal; */
    left: 0;
    top: 63px;
    width: 100%;
    overflow: auto;
    max-width: 400px;
    max-height: calc(100vh - 63px);
    background-color: #fff;
    padding: 0;
    transition: opacity 0.075s ease-in-out, visibility 0.075s ease-in-out; /* TODO: mixin? */
    opacity: 1;
    visibility: visible;
    &--hidden {
        opacity: 0;
        visibility: hidden;
    }

    &-header {
      background-color: $silver;
      border-bottom: 2px solid $scania-light-grey;
      font-weight: 700;
      font-size: 13px;
      color: $scania-black;
      padding: 15px 24px 13px;
    }

    .list-group-item {
      position: relative;
      padding: 12px 24px;
      border-color: transparent;

      &::after {
        content: '';
        position: absolute;
        width: calc(100% - 12px);
        background: $silver;
        height: 1px;
        left: 6px;
        bottom: 0px;
      }

      &:hover {
        background-color: #f5f5f5;
        color: $scania-blue;
        .list-group-item-heading {
          text-decoration: underline;
        }
        &::after {
          display: none;
        }
      }


      &:first-of-type {
        border-top: none;
      }

      &:last-child  {
        border-bottom: none;
        margin-bottom: 0;
      }


    }

    .list-group-item-heading {
      font-size: 13px;
      color: $scania-blue;
      margin-bottom: 2px;
      padding: 0;
      background-color: transparent;
      line-height: 1.2em;
    }

    .list-group-item-text {
      margin-bottom: 0;
      color: $scania-black;
      font-size: 10px;
      line-height: 1.3em;
    }
  }

  &__search {
    flex-grow: 1;
    margin-right: 25px;
    @include media-breakpoint-down(md) { 
      position: absolute;
      visibility: hidden;
      opacity: 0;
      top: 70px;
      left: 0;
      transition: opacity 0.075s ease-in-out, visibility 0.075s ease-in-out; /* TODO: mixin? */
      width: 100%;
      padding: 20px;
      background-color: $dark-grey;

      &--open {
      visibility: visible;
      opacity: 1;
      }
    }
  }

  &__logo {

    &-exit {
      margin-right: 0;
      // transform: translate(0);
    }

    &-exit.top-header__logo-exit-active {
      /* transform: translate(65px,0); */
      margin-right: -65px;
      transition: margin 150ms ease-in;
    }

    img {
      height: 35px;
      width: 100%;
    }
    div {
      background-color: $scania-white;
      width: 65px;
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    a {
      display: none;
    }
    @include media-breakpoint-down(md) {
      order: 2;
      
      a {
        display: block;

        & + img {
          display: none;
        }
      }
    }
  }

  &__mobile-wrapper {
    order: 1;
    display: none;
    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  &__mobile-menu {
    z-index: 1050;
    position: absolute;
    top: 70px;
    left: 0;
    visibility: visible;
    opacity: 1;
    transition: opacity 0.075s ease-in-out, visibility 0.075s ease-in-out; /* TODO: mixin? */
    width: 100%;
    border: none;
    max-height: calc(100vh - 70px);
    overflow-y: auto;
    display: flex;
    flex-wrap: nowrap;

    &--hidden {
      opacity: 0;
      visibility: hidden;
    }

    &.nav.flex-column {
      background-color: white;
    }

    &-reflex {

      &-toggle {
        border: none;
        background: #fff;
        display: flex;
        align-items: center;
        text-align: left;
        height: 50px;
        border-top: 3px solid #041E42;
        border-bottom: 1px solid $silver;
        padding: 0 20px 0 30px;
        font-size: 12px;
        font-weight: 700;
        color: $scania-blue;
        cursor: pointer;
        flex-shrink: 0;
        width: 100%;
        &:hover {
          background-color: $platina;
          span {
            text-decoration: underline;
          }
        }

        span {
          flex-grow: 1;
        }
        i {
          font-size: 21px;
          flex-shrink: 0;
        }
      }

      &-back {
        height: 40px;
        background-color: $dark-grey;
        padding-left: 30px;
        color: #fff;
        border: none;
        width: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        i {
          font-size: 18px;
          margin-right: 8px;
        }
        span {
          font-size: 12px;
          letter-spacing: 0.13px;

        }
      }

      .top-header__preferred-intranet {
        .list-group-item {
          height: auto;
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }

      a {
        display: flex;
        flex-direction: column;
        height: 55px;
        border-bottom: 1px solid $silver;
        padding-left: 30px;
        justify-content: center;

        &:hover {
          text-decoration: none;
          background-color: $platina;
        }
      }

      &-heading {
        font-family: $font-family-bold;
        font-size: 13px;
        line-height: 14px;
        color: $scania-black;
        margin-bottom: 2px;
        padding: 0;
        background-color: transparent;
      }

      &-text {
        margin-bottom: 0;
        color: $scania-black;
        font-size: 10px;
        line-height: 14px;
      }

      .list-group-item {
        padding: 0 0 0 30px;
        border-top: none;
        border-right: none;
        border-left: none;

        &-heading {
          font-size: 13px;
          line-height: 14px;
          color: #2D2926;
          margin-bottom: 2px;
          padding: 0;
          background-color: transparent;
        }

        &-text {
          margin-bottom: 0;
          color: #2D2926;
          font-size: 10px;
          line-height: 14px;
        }
      }
      &-startpage .list-group-item{
        background-color: transparent;
      }
    }
  }

  &__mobile-search-toggle {
    button.btn.btn-primary {
      width: 80px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: auto;
      background-color: $scania-white;
      border: none;
      border-left: 1px solid $scania-light-grey; /* fix color value */
      &:active {
        border-color: $dark-grey; /* fix color value */
        background-color: $dark-grey;
        i {
          color: white;
        }
      }
       i {
         font-size: 28px;
         margin-right: 0;
         color: $dark-grey; /* TODO: fix value */
       }

       @include media-breakpoint-down(md) { 
         border-left: none;
         border-right: 1px solid $scania-light-grey; 
       }
    }

    &--open {
      button.btn.btn-primary {
        background-color: $dark-grey;
        border-color: $dark-grey; /* fix color value */
        i {
          color: white;
        }
      }
    }
  }

  &__current-site {
    background-color: $scania-blue;
    padding-left: 30px;
    height: 50px;
    color: #fff;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:hover {
      text-decoration: none;
    }

    &:visited {
      color: #fff;
    }

    &-name {
      font-size: 13px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.08;
      letter-spacing: normal;
    }

    &-second {
      margin-top: 3px;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
    }
  }

  &__related-sites {
    li {
      background-color: $silver;
      border-bottom: 1px solid #fff;
      &:last-child {
        border-bottom: none;
      }
    }

    &-item {
      height: 50px;
      color: $scania-blue;
      padding-left: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;


      &:hover {
        color: $scania-blue;
        text-decoration: none;
      }

      
      &:visited {
        color: $scania-blue;
      }

      &-name {
        font-size: 13px;
        font-weight: bold;
      }

      &-second {
        font-size: 10px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.4;
        letter-spacing: normal;
      }
    }
  }
  .navigation-list {
		& .nav-link.nav-item-active {
      &:hover {
        text-decoration: none !important;
      }
      > span {
        border-bottom: 3px solid $orange;
      }
		}
	}
}

.top-header__preferred-intranet {
  display: flex;

  .list-group-item {
    min-height: 57px;
    width: 100%;

    &:after{
      width: 100%;
    }
  }


  &-subheading {
    font-size: 10px;
    margin-bottom:4px;
    margin-top: 4px;
    color: $scania-black;
  }


  &-toggle {
    border: none;
    background: none;
    width: 60px;
    color: $medium-grey;
    border-left: 1px solid $silver;
    border-bottom: 1px solid $silver;
    cursor: pointer;

    &:hover {
      background: $platina;
    }

    &:active, &:focus {
      outline: none;
    }
  }
}


.top-header__choose-intranet {
  &-toggle {
    padding: 15px 24px;
    background-color: $light-beige;

    .btn {
        span {
        text-transform: uppercase;
        font-family: $font-family-base;
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 0.24px;
        line-height: 12px;
        margin-top: 4px;
      }

      i {
        font-size: 13px;
      }
    }
  }

}

.top-header__favorite-item {
  display: flex;
  flex-direction: row;
  & .list-group-item {
    width: 100%;
    &:after {
      width: calc(100% - 6px);
    }
  }
  & button {
    height: 56px;
    width: 56px;
    min-width: 58px;
    font-size: 17px;
    background: transparent;
    border: none;
    border: 1px solid $silver;
    border-top: none;

    i {
      color: $scania-blue;
    }

    &:hover {
      background-color: $platina;
    }
  }
}
