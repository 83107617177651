.reflex-editor {

  &.edit {
    & .reflex-editor-textarea {
      border: 1px solid $scania-light-grey;
      padding: 15px 10px;
      min-height: 220px;
    }
  }

  &-textarea {
    color: $scania-black;
    resize: none;
    tab-size: 1;
    outline: 0;

    &::placeholder {
      color: $medium-grey
    }
  }

  &-toolbar {
    position: relative;
    display: flex;
    padding: 4px;
    border-left: 1px solid $scania-light-grey;
    border-right: 1px solid $scania-light-grey;
    border-top: 1px solid $scania-light-grey;

    &__item {
      border: 0;
      display: flex;
      background: none;
      border-radius: 10px;
      padding: 8px;
      vertical-align: middle;
      cursor: pointer;

      & span.reflex-sdds-icon {
        background-color: #000;
        width: 20px;
        height: 20px;
      }

      &:hover {
        background-color: #fafafa;
      }
    }
  }

  &-divider {
    width: 1px;
    background-color: $scania-light-grey;;
    margin: 0 4px;
  }

  &-link {
    display: initial !important;
  }

  &-paragraph {
    margin-bottom: 0;
  }
}

.reflex-link-editor {
  padding: 8px;
  width: 100%;
  background-color: #fff;
  transition: opacity 0.5s;

  & .modal-content {
    border: 0;
  }

  & label {
    margin-bottom: 0;
  }

  & .reflex-input {
    margin-bottom: 10px;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 40px;
  }

  &__label--error {
    font-size: 14px;
    color: $scania-red;
  }

  &__address--label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
  }

  &__save {
    margin-left: 15px;
  }
}
