$hit-margin: 1rem;

.search-sites-and-apps {

  .sdds-container {
    padding-left: 0;
    padding-right: 0;

    @include media-breakpoint-down(sm) {
      padding: 0 16px;
    }
  }

  .reflex-search-no-results {
    width: 860px;
    margin: 20px auto 0;
  }
}

.reflex-search__count-wrapper {
  margin-bottom: 20px;
  padding-left: 8px;
}

.reflex-search-list {
  padding-left: 0;
  padding-right: 0;
  margin: 0 -8px 0 -8px;
  padding: 0;
  list-style: none;

  &__hit {
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 35px;

    @include media-breakpoint-down(md) {
      margin-bottom: 16px;
    }
  }
}

.reflex-search__load-more-wrapper {
  padding-left: 8px;
}
