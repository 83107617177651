.reflex-modal  {
  max-width: 730px;
  margin: 11.75rem auto;
  @include media-breakpoint-down(sm) {
    margin: 1.75rem auto;
  }
  .modal-content {
    border: 0;
  }
  .modal-header {
    align-items: center;
    justify-content: center;
    background-color: $platina;
    padding: 12.5px;
    height: 60px;
    border: 0;
    border-radius: 0;
    .modal-title img {
      height: 35px;
    }
  }
  .modal-body {
    padding: 35px 60px 45px;
    @include media-breakpoint-down(sm) {
      padding: 35px 30px 45px;
    }
  }
  .modal-footer {
    align-items: center;
    justify-content: center;
    background-color: $platina;
    padding: 25px;
    border: 0;
    border-radius: 0;
    height: 69px;
  }
  .btn.btn-secondary {
    margin-right: 30px;
  }
}
