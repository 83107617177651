.search-scope-wrapper {
  display: flex;
  align-items: center;
  background-color: #e9fafe;
  padding: 15px;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 20px;

  .search-scope-info {
    margin-right: 15px;
  }

  .sdds-content-switcher {
    background-color: var(--sdds-grey-100);
    padding: 5px;
    border-radius: 4px;
    margin-left: auto;
    width: 170px;
    display: flex;
    position: relative;

    button {
      border: 0;
      border-radius: 4px;
      padding: 10px 15px;
      font-weight: 600;
      color: var(--sdds-grey-700);
      width: 50%;
      display: block;
      cursor: pointer;
      background: transparent;
      z-index: 1;

      &:hover {
        color: var(--sdds-grey-958);
      }

      &:disabled {
        cursor: default;
        color: var(--sdds-grey-700);
      }

      &.isActive {
        color: var(--sdds-grey-958);
        cursor: default;
      }
    }

    .background {
      background-color: #fff;
      color: var(--sdds-grey-958);
      transition: .4s;
      position: absolute;
      width: calc(50% - 5px);
      height: calc(100% - 10px);
      border-radius: 4px;

      &.right {
        transform: translateX(100%);
      }
    }
  }
}