.alerts {
  z-index: 1051;
  position: fixed;
  width: 100%;
  top: 81px;
  transition: top 150ms ease-in;

  &-transition-group-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  @include media-breakpoint-down(xs) {
    top: 86px;
  }

  &--scrolled {
    top: 16px;
  }
}

.banner-alert {
  &.alert {
    margin-bottom: 0;
  }
  & .alert-content {
    margin: 0;
    a {
      color: $scania-black;
      i {
        margin-left: 10px;
      }
    }
  }

}

.alert-enter {
  opacity: 0.01;
}

.alert-enter.alert-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.alert-exit {
  opacity: 1;
}

.alert-leave.alert-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
