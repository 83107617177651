.reflex-select {
  &__container {
    width: 100%;
    max-width: 540px;
  }
  &__menu-list {
    max-height: 335px;
  }
  &__control {
    border-radius: 0;
    background-color: $scania-white;
    border-color: $scania-light-grey;
    min-height: 50px;
    box-shadow: none;
    &:hover {
      border-color: $scania-light-grey;
    }

    &--menu-is-open {
      .reflex-select__dropdown-indicator {
        transform: rotate(180deg);
      }
    }
  }

  &__value-container {
    padding: 14px;
    background:  $scania-white;
  }

  &__placeholder {
    color: $medium-grey;
    font-size: 15px;
    font-weight: 400;
  }

  &__input {
    font-size: 15px;
  }

  &__multi-value {
    background-color: $medium-grey;
    color: #fff;
    height: 25px;
    display: flex;
    align-items: center;

    &__label {
      color: #fff;
      letter-spacing: 0.11px;
      font-size: 11px;
      font-family: "Scania Sans Condensed";
      padding: 0 9px;
    }

    &__remove {
      align-self: stretch;
      cursor: pointer;
      &:hover {
        color: #fff;
        background-color: $medium-grey;
      }
    }
  }
  &__single-value{
    color: $scania-black;
  }
  &__clear-indicator {
    cursor: pointer;
    padding: 0 10px;
  }

  &__indicator-separator {
    margin: 0;
    background-color: $scania-light-grey;
  }

  &__dropdown-indicator {
    background-color: #fff;
    color: $scania-black;
    font-size: 26px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: $scania-black;
    }
  }

  &__menu {
    border-radius: 0;
    box-shadow: none;
    border: 1px solid $scania-light-grey;
    margin-top: 0;

    &-list {
      padding: 0;
    }
  }

  &__option {
    height: 45px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;
    color: $scania-black;
    background-color: transparent;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      background: $silver;
    }

    &--is-selected, &--is-selected:hover  {
      color: #fff;
      background: $medium-grey;
    }
  }
  &__people{
    &-content {
      @include ellipsis();
      display: flex;
      flex-direction: column;
      
      &__title {
        @include ellipsis();
      }
      &__company {
        font-size: 11px;
        color: #2D2926;
        @include ellipsis();
      }
    }
    &-img {
      height: 30px;
      width: 30px;
      height: 30px;
      max-width: 100%;
      @include object-fit(cover);
      border: 2px solid $scania-light-grey;
      border-radius: 50%;
      margin-right: 10px;
    }
    .fallback {
      margin-right: 10px;
      i {
        color: $medium-grey;
        font-size: 13px;
        width: 30px;
        height: 30px;
        border: 2px solid $silver;
        border-radius: 50%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}


.reflex-select__container-small {
  width: 120px;

  .reflex-select {
    &__control {
      min-height: 30px;
      font-size: 11px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
    }

    &__value-container {
      padding: 0 10px;
    }

    &__dropdown-indicator {
      height: 28px;
      width: 28px;
      font-size: 16px;
    }

    &__option {
      height: 35px;
      font-size: 11px;
      padding: 0 15px;
    }
  }
}