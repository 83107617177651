.reflex-joyride {
  dl > div {
    display: flex;
    align-items: center;

    & dt > i {
      font-size: 22px;
      margin-right: 10px;
    }

    & dd {
      margin-bottom: 0;
    }
  }

  .content {
    max-width: 700px;
  }

  .sdds-btn {
    padding: 16px;
    border: 1px solid;
    font: var(--sdds-detail-02);
    letter-spacing: var(--sdds-detail-02-ls);
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    border: none;
    box-shadow: none;
    position: relative;
    text-decoration: none;
    cursor: pointer;
  }
}
