.starmark-button.btn.btn-flat {
  font-size: 12px;
  font-weight: bold;

  &:hover {
    text-decoration: none !important;
    span {
      text-decoration: underline;
    }
  }

  i {
    font-size: 19px;
    margin-right: 6px;
  }
}