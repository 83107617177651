.search-people-and-organisations {

  .sdds-container {
    padding-left: 0;
    padding-right: 0;

    @include media-breakpoint-down(sm) {
      padding: 0 16px;
    }
  }

  .reflex-search-no-results {
    width: 860px;
    margin: 20px auto 0;
  }

  &__wrapper {
    display: block;
    max-width: 100%;
  }

  &-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    margin: 0 -8px 0 -8px;

    &__hit {
      padding-left: 8px;
      padding-right: 8px;
      margin-bottom: 35px;
  
      @include media-breakpoint-down(md) {
        margin-bottom: 16px;
      }
    }
  }
}
