.profile-card {
  display: flex;
  &__placeholder {
    svg {
      max-height: 200px;
    }
    &-sm-up {
      display: none;
    }
  }

  > div {
    min-width: 0;

    .card-subtitle {
      white-space: normal;
    }

    .card-email a {
      white-space: normal;
      word-break: break-word;
    }

    .card-title {
      line-height: 1.5rem;
    }
  }

  &__links {
    margin: 10px 0;
    > span {
      padding-bottom: 8px;
    }
  }

  &__avatar {
    flex-shrink: 0;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    @include object-fit(cover);
    border: 2px solid $scania-light-grey;
    margin-right: 15px;

    &.fallback-profile {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid $scania-light-grey;

      & span.reflex-sdds-icon {
        background-color: #96999B; // Change later when we're using SDDS
        width: 25px;
        height: 25px;
      }
    }
  }

  .fallback {
    margin-right: 15px;
    min-width: 55px;
    i {
      color: $medium-grey;
      font-size: 22px;
      width: 55px;
      height: 55px;
      border: 2px solid $scania-light-grey;
      border-radius: 50%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @include media-breakpoint-up(md) {
    display: block;

    &__placeholder {
      svg {
        max-height: 243px;
      }
      &-sm-up {
        display: block;
      }
      &-sm-down {
        display: none;
      }
    }

    &__avatar {
      width: 75px;
      height: 75px;
      margin: 0 0 10px 0;
      
      &.fallback-profile {
        & span.reflex-sdds-icon {
          width: 30px;
          height: 30px;
        }
      }
    }
    .fallback {
      margin: 0 0 10px 0;
      i {
        width: 75px;
        height: 75px;
        font-size: 28px;
      }
    }
  }
  &-small {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    padding: 10px;
    align-items: flex-start;
    &:hover {
      background-color: $platina;
      border-radius: 1px;
      text-decoration: none;
      .profile-card-small-content-title {
        text-decoration: underline;
      }
    }
    &-img {
      width: 30px;
      height: 30px;
      min-width: 30px;
      max-width: 100%;
      @include object-fit(cover);
      border: 2px solid $silver;
      border-radius: 50%;
      margin-right: 10px;

      &.fallback-profile {
        display: flex;
        justify-content: center;
        align-items: center;

        & span.reflex-sdds-icon {
          background-color: #96999B; // Change later when we're using SDDS
          width: 13px;
          height: 13px;
        }
      }

      &.fallback {
        margin-right: 10px;
        i {
          color: $medium-grey;
          font-size: 13px;
          width: 30px;
          height: 30px;
          border: 2px solid $silver;
          border-radius: 50%;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    &-icon {
      margin-right: 10px;
      i {
        color: $medium-grey;
        font-size: 13px;
        width: 30px;
        height: 30px;
        border: 2px solid $silver;
        border-radius: 50%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &-content {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      font-weight: bold;
      overflow: hidden;
      line-height: 15px;
      color: $scania-black;
      > div {
        @include ellipsis;
      }
      &-title {
        color: $scania-blue;
        margin-bottom: 2px;
        font-size: 13px;
      }
      &-url {
        font-size: 12px;
        font-weight: normal;
        color: $url-link;
      }
      &-company {
        font-size: 11px;
        color: $scania-black;
      }
      &-location {
        font-size: 11px;
        font-weight: normal;
        @include fontAwesome(map-marker-alt) {
          color: $medium-grey;
          margin-right: 5px;
        }
      }
      &-phone {
        font-size: 9px;
        color: $blue;
        font-weight: normal;
        @include fontAwesome(phone) {
          color: $medium-grey;
          margin-right: 5px;
        }
      }
      &-timestamp {
        color: $medium-grey;
      }
    }
    &__placeHolder {
      width: 100%;
      height: 52px;
    }
  }

  &--email {
    .profile-card__avatar {
      min-width: 75px;
      margin: 0 0 10px 0;
      border: 0;

      @include media-breakpoint-down(sm) {
        width: 55px;
        height: 55px;
      }

      i {
        color: $medium-grey;
        width: 75px;
        height: 75px;
        font-size: 28px;
        border: 2px solid $scania-light-grey;
        border-radius: 50%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-down(sm) {
          width: 55px;
          height: 55px;
          font-size: 22px;
        }
      }
    }
  }
}

.sv-com-scania-webapp-profilecards {
  display: inline-flex;

  @include media-breakpoint-down(sm) {
    display: block;
  }

  .card {
    height: fit-content;
  }
}

.profile-card-wrapper {
  height: fit-content;
}
