.search-list {
  margin-left: 20px;
  margin-right: 20px;

  .search-field{
    padding: 20px 21px 20px 21px;
    background-color: $light-beige;
    display: block;
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
    max-width: none;

    &__title {
      font-size: 10px;
      font-family: $font-scania-sans-bold;
      margin-bottom: 8px;
      color: $scania-black;
    }

    &__input {
      display: flex;

      input::placeholder{
        color: $scania-black;
      }
    }
  }

  .form-group {
    margin: 0;
  }

  .input-group {
    height: auto;
  }

  .input-group-prepend {
    display: flex;
    align-items: center;
    position: relative;

    &:after {
      content: "";
      border-right: 1px solid $medium-grey;
      height: 16px;
      position: absolute;
      right: 0px;
      top: 10px;
    }
  }

  .input-group-text {
    background-color: $white;
    border: none;
    font-size: 21px;
    height: 35px;
  }

  .selected-intranet{
    padding: 15px 0px 20px 0px;
    color: $scania-black;

    &__header {
      margin-bottom: 8px;
      font-size: 10px;
      font-family: $font-scania-sans-bold;
    }

    &-item {
      background-color: $platina;
      padding: 9px 10px 7px 10px;

      &__title {
        font-size: 13px;
        font-family: $font-scania-sans-bold;
        margin-right: 6px;
      }

      &__subtitle {
        font-size: 12px;
      }

      &__desc {
        font-size: 10px;
        margin-top: 4px;
      }
    }
  }

  &-dropdown {
    margin-top: -20px;
    padding: 0px;
    border-bottom: 2px solid $silver;
    border-left: 1px solid $silver;
    border-right: 1px solid $silver;

    &-item {
      width: 100%;
      padding: 13px;
      font-family: $font-family-bold;
      font-size: 13px;
      background-color: #fff;
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      position: relative;
      height: 45px;

      &:nth-child(odd) {
        background-color: $scania-white;
      }

      &__label {
        padding: 12px 18px;
        width: 100%;
        display: block;
        text-align: left;
        color: #3C454C;
        cursor: pointer;
        position: relative;
        z-index: 2;
        transition: color 200ms ease-in;
        overflow: hidden;

        &:before {
          content: '';
          z-index: 1;
          opacity: 0;
          transform: scale(0);
          width: 12px;
          height: 12px;
          background: $scania-blue;
          position: absolute;
          right: 13px;
          top: 39%;
          border-radius: 100%;
          transition: all 0.2s ease;
        }

        &:after {
          content: '';
          width: 18px;
          height: 18px;
          border: 1px solid $scania-light-grey;
          border-radius: 100%;
          background: $platina;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          transition: all 200ms ease-in;
        }
      }

      &__title {
        font-size: 12px;
      }

      &__subtitle {
        font-size: 10px;
        margin-top: 4px;
        color: $scania-black;
        font-family: $font-scania-sans;
      }

      input:checked ~ label {
        &:before {
          opacity: 1;
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }
      input {
        width: 32px;
        height: 32px;
        order: 1;
        z-index: 2;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        visibility: hidden;
      }
    }
  }

  &-button__wrapper {
    padding: 21px 11px 41px 0px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    border-left: 1px solid $silver;
    border-right: 1px solid $silver;
    border-bottom: 1px solid $silver;

    .btn-flat {
      color: $blue;
      font-size: 15px;
    }

    .btn-secondary {
      margin-right: 15px;
    }

    .search-list-button__save{
      font-family: $font-scania-sans-bold;
    }
  }

  &__no-results{
    padding: 15px;
    margin-top: -20px;
    border-left: 1px solid $silver;
    border-right: 1px solid $silver;
    background-color: $white;
    z-index: 0;
    position: relative;
  }
}