.sdds-modal-backdrop {
  display: flex;
  z-index: 2000;

    .sdds-modal {

      &-header {
      align-items: center;
      justify-content: space-between;

      h2 {
        margin: 0;
      }
    }

    &-body {
      &__iframe-wrapper {
        margin-top: 20px;

        iframe {
          border: 0;
          width: 100%;
        }
      }
    }

    &-close {
      font-size: 26px;
    }

    &-actions {
      .sdds-btn {
        border-radius: 4px;
        padding: 12px;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.01em;

        &-primary {
          color: var(--sdds-white);

          &:hover {
            color: var(--sdds-white); 
            text-decoration: none;
          }
      
          &:visited {
            color: var(--sdds-white);
          }
        }

        & i {
          margin-left: 10px;
        }
      }
    }
  }
}

body.popup-modal-visible {
  width: 100%;
}
