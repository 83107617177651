.sdds-textfield {
  position: relative;
  width: 100%;

  & label {
    display: block;
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 16px;
    color: var(--sdds-textfield-label-color);

    &.sr-only {
      margin-bottom: 0;
    }
  }

  & input {
    position: relative;
    border-radius: 4px 4px 0 0;
    width: 100%;
    height: 100%;
    font-family: var(--sdds-font-family-semi-condensed);
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: var(--sdds-textfield-color);
    background-color: var(--sdds-textfield-bg);
    padding: 0 16px;
    margin: 0;
    border: 0;
    border-bottom: 1px solid var(--sdds-textfield-border-bottom);
    outline: none;
    transition: border-bottom-color 200ms ease;

    &:hover {
      border-bottom-color: var(--sdds-textfield-border-bottom-hover);
    }

    &::placeholder {
      opacity: 1;
      color: var(--sdds-textfield-placeholder);
    }
  
    &:focus {
      &::placeholder {
        color: var(--sdds-textfield-placeholder-focus-color);
      }

      + .sdds-textfield-bar::before,
      + .sdds-textfield-bar::after {
        width: 50%;
      }
    }
  
    &:disabled {
      background-color: var(--sdds-textfield-disabled-bg);
      color: var(--sdds-textfield-disabled-color);
      border-bottom: 1px solid transparent;
  
      &::placeholder {
        color: var(--sdds-textfield-disabled-placeholder);
      }
    }

    &:read-only {
      pointer-events: none;
    }
  }

  &-bar {
    position: absolute;
    width: 100%;
  
    &::before,
    &::after {
      content: "";
      height: 2px;
      bottom: 0;
      width: 0;
      position: absolute;
      background-color: var(--sdds-blue-400);
      transition: 200ms ease all;
    }

    &::before {
      left: 50%;
    }
  
    &::after {
      right: 50%;
    }
  }
}
