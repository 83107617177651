
footer.footer-connect {
  position: absolute;
  width: 100%;
  min-height: 150px;

  .container-fluid {
    padding: 20px 55px 20px 30px;

    @include media-breakpoint-down(xs) {
      padding: 40px 25px 50px 25px;
    }

    .footer-wrapper {
      min-height: 150px;
      height: 100%;
      justify-content: space-between;

      @include media-breakpoint-down(xs) {
        min-height: 0px;
        justify-content: normal;
        flex-direction: column-reverse;
      }

      .footer-link-wrapper {
        @include media-breakpoint-down(xs) {
          width: 100%;
          text-align: center;
        }

        &__link {
          text-transform: uppercase;
          font-size: 15px;
          margin-right: 25px;
          font-weight: bold;

          @include media-breakpoint-down(xs) {
            margin-left: 12.5px;
            margin-right: 12.5px;
          }
        }
      }

      .footer-logo {
        @include media-breakpoint-down(xs) {
          display: block;
          width: 100%;
          margin-bottom: 35px;
        }

        .footer-scania-logo {
          display: block;
          width: 193px;
          height: auto;
          @include media-breakpoint-down(xs) {
            margin: auto;
          }
        }
      }
    }

    .footer-copyrigh {
      margin-top: 15px;
    }
  }
}