.reflex-preview-modal {
  .error {
    position: absolute;
    z-index: 99;
  }
  .modal-body {
    height: 600px;
    padding: 0;
    @include media-breakpoint-down(sm) {
      height: 700px;
    }
  }
}


.crop-container {
  display: flex;
  position: relative;
  width: 100%;
  height: 400px;
}
.crop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.controls {
  height: 185px;
  display: flex;
  flex-direction: row;
  width: 100%;
  bottom: 0;
  align-items: center;
  justify-content: space-around;
  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse;
    height: 300px;
  }

  &-range {
    display: flex;
    flex-direction: column;
    width: 170px;
    .form-group {
      display: flex;
      align-items: center;
      @include media-breakpoint-down(sm) {
        display: inline-block;
        margin-bottom: 0;
      }
      label {
        margin-right: 10px;
        margin-bottom: 0;
      }
    }
    .custom-range {
      width: 170px;
    }
    > button {
      margin-top: 15px;
    }
  }
}

.avatar-preview {
  height: 150px;
  width: 150px;
    img {
      border-radius: 50%;
      width: 150px;
      height: 150px;
      @include object-fit(cover);
      border: 2px solid $silver;
      background-color: $scania-blue;
  }
}
