.search-enterprise {

  & .sdds-container {
    padding: 0;
    width: 900px;

    @include media-breakpoint-down(sm) {
      padding: 0 16px;
    }
  }

  &-hits {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  &-other-searched-for {
    font-family: var(--sdds-font-family-sans);
    color: var(--sdds-negative);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 4px;
    letter-spacing: -0.01em;

    button {
      color: var(--sdds-blue-500);
      background-color: transparent;
      border: 0;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .no-hits {
    display: none;
  }
}
