.document-card {

  .card-body {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    justify-content: space-between;
  }

  &__image_status-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  &__image {
    min-height: 48px;
    max-height: 117px;
  }

  &__status {
    background: $medium-grey;
    color: #fff;
    align-self: flex-start;
    font-size: 9px;
    height: 20px;
    padding: 5px 10px;
  }

  &__contact {
    font-size: 14px;
    margin-bottom: 13px;
    &-label {
      font-family: $font-family-bold;
    }

    &-placeholder {
      width: 60px;
      height: 10px;
    }
  }

  &__date {
    font-size: 14px;
  }

  &__download {
    width: 100%;
    i {
      font-size: 16px;
    }
  }


  &__error {
    i {
      font-size: 48px;
      color: $scania-light-grey;
      margin-bottom: 20px;
    }
  }


  @include media-breakpoint-down(sm) {
    height: 145px;

    .card-body {
      height: 145px;
      padding: 15px;
      flex-direction: row;
    }

    .card-title {
      font-size: 18px;
      margin-bottom: 10px;
      font-weight: bold;
      font-style: normal;
      font-stretch: condensed;
      line-height: 1.22;
      letter-spacing: 0px;
    }

    &__wrapper {
      flex-grow: 1;
      min-width: 0;
    }

    &__image {
      margin-right: 16px;
      flex-shrink: 0;
      @include object-fit(contain);
      align-self: flex-start;
      max-width: 80px;
    }

    &__status {
      display: inline-block;
      margin-bottom: 8px;
    }

    &__contact {
      font-size: 13px;
      margin-bottom: 8px;
    }

    &__date {
      display: block;
      font-size: 11px;
    }


    .document-card__download.btn.btn-secondary {
      flex-shrink: 0;
      align-self: flex-end;
      width: 35px;
      min-width: 0;
      span {
        display: none;
      }
      i {
        margin-right: 0;
      }
    }


    &__error {
      display: flex;
      i {
        margin-right: 16px;
        margin-bottom: 0;
      }
    }
  }
}