.sharepoint-list {
  h3,
  h4,
  button,
  i,
  span,
  input,
  div {
    font-size: initial !important;
  }

  &-config {
    .form-check {
      margin-top: 20px;

      label:after {
        font-size: 4rem;
      }
    }
  }

  &-navigator {
    display: flex;
    gap: 25px;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    &-breadcrumbs {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      margin-bottom: 15px !important;

      button,
      span {
        max-width: fit-content;
        flex: 1;
        font-size: 14px;
        padding: 0;
      }
      button:hover {
        text-decoration: underline;
      }
    }

    .reflex-sdds-icon {
      display: inline-block;
      min-width: 32px;
      min-height: 32px;
      background-color: #041e42;
      vertical-align: middle;
      margin-right: 4px;
    }

    button:not(.sdds-btn) {
      height: 100%;
      width: 100%;
      padding: 0;
      appearance: none;
      background: transparent;
      border: none;
      text-align: left;

      &.button-select {
        min-height: 20px;
        min-width: 20px;
        border: 1px solid #949494;
        border-radius: 50%;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 20px;
      }

      &.button-enter {
        & .fal {
          font-size: 5rem !important;
        }

        &:hover {
          span {
            text-decoration: underline;
          }
        }
      }

      &.button-remove {
        padding: 0;
        width: 26px;
        text-align: center;

        &:hover {
          text-decoration: underline;
        }

        & .fal {
          font-size: 24px;
        }
      }

      &.button-move {
        width: 26px;
        text-align: center;

        & i.fal {
          font-size: 6rem !important;
        }
      }

      &.active {
        cursor: default;

        & .checked-holder {
          display: block;
          height: 14px;
          width: 14px;
          border-radius: 50%;
          background-color: #949494;
        }
      }

      & .fal {
        font-size: 26px;
      }
    }

    button.sdds-btn {
      margin-top: 10px;
      width: fit-content;
      border-radius: 4px;
    }

    h3 {
      margin-top: 20px;
      margin-bottom: 10px;
    }

    h4 {
      display: flex;
      align-items: center;
      margin: 0;
      font-size: 18px;
      gap: 10px;
    }

    &-document-list,
    &-selected-list {
      display: flex;
      flex-direction: column;
      flex: 1 0 45%;
    }

    &-item {
      position: relative;
      display: flex;
      gap: 10px;
      align-items: center;
      padding: 10px;
      border-top: 1px solid #cccccc;

      &:nth-child(even) {
        background-color: #f7f7f7;
      }

      h4 {
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 2;
        // display: block;
        // display: -webkit-box;
        // overflow: hidden;
        flex: 1;
        border-bottom: none;

        & .fal {
          font-size: 26px;
        }
      }
    }

    &-document-list {
      .search-documents {
        position: relative;

        button {
          position: absolute;
          width: 30px;
          height: 30px;
          top: 14px;
          right: 10px;
        }
      }

      .search-documents-input {
        margin: 0 0 15px;
        padding-left: 10px;
        height: 40px;
        width: 100%;
      }
    }
  }
  &-error {
    opacity: 1;
    display: flex !important;
    &::before {
      font-size: 5rem;
    }
  }
}
