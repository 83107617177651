.reflex-sdds-select {

  &:hover {
    &.reflex-sdds-select__control {
      border-bottom-color: var(--sdds-grey-600);
    }
  }

  &__option,
  &__single-value,
  &__placeholder {
    font-family: var(--sdds-font-family-semi-condensed);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }

  &__menu {
    margin: 0;
    border-left: 1px solid var(--sdds-grey-300);
    border-right: 1px solid var(--sdds-grey-300);
    border-bottom: 1px solid var(--sdds-grey-300);
    border-radius: 0;

    @include media-breakpoint-down(md) {
      position: relative;
    }

    &-list {
      padding: 0;

      @include media-breakpoint-down(md) {
        max-height: 100%;
      }
    }
  }

  &__option {
    color: var(--sdds-grey-900);
    border-bottom: 1px solid var(--sdds-grey-300);
    padding: 12px 16px;

    &--is-focused {
      background-color: var(--sdds-grey-100);
    }

    &--is-disabled {
      color: var(--sdds-grey-400);
      cursor: not-allowed;
    }

    &--is-selected {
      background-color: var(--sdds-grey-100);
    }
  }

  &__control {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 4px 4px 0 0;
    box-shadow: none;
    cursor: pointer;
    transition: border-color 200ms ease-in;

    &--is-focused {
      &::before {
        content: ' ';
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: 2px solid var(--sdds-blue-400);
      }
    }
  }

  &__indicator {
    & svg {
      width: 16px;
      height: 16px;
      color: var(--sdds-grey-700);
    }

    & span.reflex-sdds-icon {
      width: 16px;
      height: 16px;
      background-color: var(--sdds-grey-700);
    }

    &-separator {
      display: none;
    }
  }
}
