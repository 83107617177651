.top-header__notifications {
  min-width: 40px;
  height: 45px;
  margin-right: 25px;
  position: relative;
  display: -webkit-inline-box;
  background-color: #041E42;

  @include media-breakpoint-down(md) {
    width: 100%;
    padding: 0 35px;
    margin: 0;
    height: 50px;
  }

  > button {
    display: flex;
    background-color: transparent;
    border: none;
    padding: 0;
    align-items: center;
    color: #fff;
    position: relative;

    .dot-notifications {
      top: 6px;
      right: 15px;
      height: 13px;
      width: 13px;
      background-color: #D6001C;
      border-radius: 50%;
      position: absolute;
    }

    .icon {
      margin-right: 10px;
      font-size: 25px;
    }
    .cheveron-up, .cheveron-down {
      height: 15px;
      width: 15px;
    }
  }
}

.notificationsMenu {
  position: absolute;
  top: 50px;
  right: 220px;
  width: 400px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  padding: 15px 0px 10px 15px;
  border: 1px solid $scania-light-grey;
  z-index: 1041;
  border-radius: 4px;

  @include media-breakpoint-down(md) {
    position: initial;
    top: auto;
    width: 100%;
  }

  p {
    color: #000;
    margin: 0;
  }

  .notificationsMenuTop {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    margin-right: 15px;
    border-bottom: 1px solid $scania-light-grey;
    font-size: 14px;
    padding: 5px 0;
    
    .notificationsMenuTop_readAll {
      cursor: pointer;
      font-size: 14px;
      position: relative;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .no-notifications {
    padding: 20px 0;
  }

  .notificationsList {
    max-height: 550px;
    overflow-y: auto;
    padding: 0;
    margin: 0; 
    list-style: none;
    padding-right: 10px;

    li {
      border-top: 1px solid $scania-light-grey;
      list-style-type: none;
      margin: 0 0 8px;
      position: relative;
      @include media-breakpoint-down(md) {
        margin-right: 15px;
      }

      &:first-of-type {
        padding-top: 0px;
        border: none;
      }

      &:hover {
        .sdds-detail-03 {
          text-decoration: underline;
        }
      }

      .dot {
        &-unread {
          top: 15px;
          right: 15px;
          height: 10px;
          width: 10px;
          background-color: #4A89F3;
          position: absolute;
          border-radius: 50%;
        }
      
        &-read {
          position: absolute;
          border-radius: 50%;
          top: 15px;
          right: 15px;
          height: 10px;
          width: 10px;
          border: 2px solid #4A89F3;
          background-color: transparent;
        }
      }

      .siteinformation {
        display: flex;
        padding-top: 8px;
      }

      a:after {
        content: "";
        inset: 0;
        position: absolute;
        z-index: 2;
      }

      .published {
        padding-top: 5px;
        font-size: 12px;
      }
    }
  }

  .loadMore-btn {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    margin-right: 15px;
    border-top: 1px solid $scania-light-grey;
  }
}
.sdds-btn {
  padding: 16px;
  border: 1px solid;
  font: var(--sdds-detail-02);
  letter-spacing: var(--sdds-detail-02-ls);
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  border: none;
  box-shadow: none;
  position: relative;
  text-decoration: none;
  cursor: pointer;
}