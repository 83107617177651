.sharepoint-document-list {
  &__title {
    background-color: $silver;
    color: $scania-black;
    display: flex;
    align-items: center;
    padding: 8px 20px;
    font-weight: 700;
    font-size: 15px;
    letter-spacing: 0.2px;
    display: flex;
    justify-content: space-between;
    min-height: 46px;

    @include media-breakpoint-down(xs) {
      padding: 10px 15px;
      margin-right: 0;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  li.document {
    background-color: $scania-white;
    padding: 17px 20px;
    margin-bottom: 5px;
    position: relative;
    display: flex;
    align-items: center;

    @include media-breakpoint-down(xs) {
      padding: 12px 15px;
    }
  }

  .reflex-sdds-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-color: $scania-black;
    display: block;
  }

  a {
    font-weight: 700;
    color: $scania-blue;
    font-size: 13px;
    text-decoration: none;
    margin-right: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &:hover {
      text-decoration: underline;
    }

    &:after {
      content: "";
      inset: 0;
      position: absolute;
      z-index: 2;
    }
    & i.fal {
      font-size: 18px !important;
      margin-right: 8px;
    }
  }

  time {
    margin-left: auto;
    flex-shrink: 0;
  }

  .path {
    display: flex;
    font-size: 13px;
    flex-wrap: wrap;
    margin-right: 10px;

    span {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .sort-change-wrapper {
    z-index: 5;

    @include media-breakpoint-down(xs) {  
      display: none;
    }

    .reflex-select__control {
      cursor: pointer;
    }
  }
  .sharepoint-list-error:before {
    font-size: 20px;
  }
}