.reflex-error {
  max-width: 900px;

  & p:last-of-type {
    border-top: 1px solid $silver;
    padding-top: 27px;
    margin-bottom: 4px;
  }
}

.connect-header-psudo {
  height: 65px;
  width: 100%;
  display: block;
  background-color: $scania-blue;
}


