.search-sharepoint {

  .sdds-container {
    max-width: 900px;
    padding: 0;
  }

  .search-sharepoint-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    margin-bottom: 24px;
  }

  .search-sharepoint-hit {
    padding: 24px 0;
    border-bottom: 1px solid var(--sdds-grey-300);

    &__title-wrapper {
      margin-bottom: 5px;
      display: flex;
      align-items: center;

      .reflex-sdds-icon {
        width: 22px;
        height: 22px;
        background-color: black;
        display: block;
        margin-right: 8px;
      }
    }

    &__title {
      font-family: var(--sdds-font-family-sans);
      color: var(--sdds-blue-500);
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.02em;
      display: block;
  
      &:hover {
        color:var(--sdds-blue-500);
        text-decoration: var(--sdds-link-text-decoration);
        text-decoration-color: var(--sdds-blue-500);
      }
  
      &:visited {
        color: var(--sdds-blue-500);
        text-decoration-color: var(--sdds-blue-500);
      }
    }

    &__desc {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: var(--sdds-grey-958);
      letter-spacing: -0.01em;
      margin: 8px 0 0 0;
    }
  }
}