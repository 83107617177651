.news-list-config{
  font-size: 4rem;

  label {
    display: block;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: .875em;
    line-height: 1.42857143;
  }

  select {
    padding: 6px 12px;
    font-size: 14px;
    color: #555;
    border: 1px solid #ccc;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  button {
    padding: 6px 12px;
    border-radius: 4px;
    background-color: #fff;
    border-color: #ccc;
    color: #333;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    &:hover {
      color: #333;
      background-color: #e6e6e6;
      border-color: #adadad;
    }
  }

  .select__value-container {
    width: 100%;

    & .css-b8ldur-Input {
      width: 100%;

      .select__input {
        width: 100%;

        & > input {
          width: 100% !important;
        }
      }
    }
  }
}
