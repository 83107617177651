.news-promotion-card {
    .card-title,
    .card-text {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        display: block;
        display: -webkit-box;
        overflow: hidden;
    }

    .card-title {
        margin: 0;
        padding: 0;
    }

    .card-text {
        white-space: pre-wrap;
        margin: 0;
        padding: 0;
    }
}

.news-promotion-hero {
    .card-title,
    .card-text {
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box;
        overflow: hidden;
        white-space: pre-wrap;
    }

    .card-title {
        -webkit-line-clamp: 3;
        padding: 3px 0;
    }

    .card-text {
        -webkit-line-clamp: 4;
        padding: 3px 0;
    }
}