.window-had-scrollbar .search-jumbotron {

  @include media-breakpoint-up(lg) {
    margin-left: -17px;
  }
}

.search-jumbotron {
  padding: 0;
  padding-top: 42px;
  border-bottom: solid 1px #D6DADE;
  margin-bottom: 0;
  border-radius: 0px;
  background-color: var(--sdds-white);

  & .sdds-container {
    padding: 0;
    width: 900px;

    @include media-breakpoint-down(sm) {
      padding: 0 16px;
    }
  }

  & > .container {
    margin-left: auto;
  }

  &__label {
    font-family: $font-family-base;
    font-size: 12px;
    font-weight: bold;
    color: $scania-black;
  }

  &__form {
    margin-bottom: 16px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }

    &-wrapper {
      width: 900px;
      margin-bottom: 0;
    }
  }

  .header-typeahead {
    padding: 0;
    position: static;
    margin-right: 0;

    form {
      max-width: 100%;
    }

    .input-group {
      max-width: 900px;
      height: auto;
    }

    input[type="text"] {
      font-size: 13px;
      height: 55px;
      padding: 0 25px 0 25px;
      background-color: var(--sdds-grey-50);
    }

    .input-group-append button.btn {
      height: 55px;
      width: 60px;
      border: 1px solid #c8c9c7;
    }

    &-results {
      top: auto;
      width: 856px;

      @include media-breakpoint-down(sm) {
        height: 100vh;
        height: calc(var(--vh, 1vh) * 50);
      }
    }
  }
}

.search-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-filter {
    display: flex;
    align-items: center;
    background-color: var(--sdds-blue-600);
    color: var(--sdds-white);
    z-index: 11;
    border-radius: 4px;
    padding: 12px;
    border: 0;
    font-size: 14px;
    line-height: 16px;
    max-height: 40px;
    letter-spacing: -0.01em;
    font-family: var(--sdds-font-family-semi-condensed);
    transition: background-color 200ms ease-in;
    cursor: pointer;

    @include media-breakpoint-down(md) {
      padding: 4px 8px;
      font-size: 12px;
      line-height: 16px;
    }

    &:disabled {
      background-color: var(--sdds-white);
      color: var(--sdds-grey-400);
      border: 1px solid var(--sdds-grey-400);
      cursor: not-allowed;
    }
    
    &:hover:enabled {
      background-color: var(--sdds-blue-500);
    }

    
    & span.reflex-sdds-icon {
      background-color: var(--sdds-white);
      margin-left: 25px;
      width: 20px;
      height: 20px;

      @include media-breakpoint-down(sm) {
        width: 14px;
      }
    }
  }

  &-help-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    appearance: none;
    border: none;
    color: $black;
    font-size: 14px;
    margin-left: 12px;
    margin-top: 10px;

    &:hover {
      text-decoration: none;
    }

    &:visited {
      color: $black;
    }

    & i {
      height: 25px;
      width: 25px;
      margin-bottom: 3px;
      background-image: url("https://cdn.digitaldesign.scania.com/icons/website_hosted/support.svg");
    }
  }

  &-section {
    display: flex;
  }

  &-wrapper {
    display: flex;
    align-items: center;
  }

  &__item {
    font-family: var(--sdds-font-family-semi-condensed);
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.01em;
    background-color: $white;
    color: var(--sdds-blue-900);
    text-align: center;
    padding: 26px 0;
    margin-right: 32px;
    transition: border 200ms ease-in;
    position: relative;
    opacity: 0.6;

    @include media-breakpoint-down(xs) {
      font-size: 12px;
      padding: 18px 0;
      margin-right: 14px;
      margin-top: 8px;
    }


    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      display: block;
      width: 0;
      height: 2px;
      background: var(--sdds-blue-300);
      transition: width 200ms ease-in;
    }

    &:hover {
      opacity: 1;
      color: var(--sdds-grey-958);
      text-decoration: none;
      
      &::after {
        width: 100%;
      }
    }

    &:visited {
      color: inherit;
    }

    &.active {
      opacity: 1;
      color: var(--sdds-grey-958);

      &:hover {
        color: var(--sdds-blue-900);
        &::after {
          background: var(--sdds-blue-300);
        }
      }
      
      &::after {
        width: 100%;
        background: var(--sdds-blue-400);
      }
    }
  }
}
