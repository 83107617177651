.action-button{
    display: block;
    padding: 18px 60px 18px 60px;
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    
    @include media-breakpoint-down(md) {
      padding: 16px 60px 16px 50px;
    }
  
    &:hover {
      text-decoration: none;
    }
  
    &--blue {
      background-color: $scania-blue;
    }
  
    &--grey {
      background-color: $medium-grey;
    }
  
    &--green {
      background-color: $green;
    }
  
    &--red {
      background-color: $scania-red;
    }
  
    &--beige {
      background-color: $beige;
    }
  
    &__icon {
      position: absolute;
      left: 20px;
      top: 18px;
      font-size: 20px;
      color: $white;
  
      @include media-breakpoint-down(md) {
        font-size: 15px;
        top: 16px;
      }
    }
  
    &__title {
      color: $white;
      font-size: 16px;
      line-height: 1.3;
      font-family: $font-family-bold;
    }
  
    &__comment {
      color: $white;
      font-size: 15px;
      line-height: 1.45;
      margin-top: 4px;
      pointer-events: none; /* remove phonenumber in safari */
  
      @include media-breakpoint-down(md) {
        font-size: 13px;
        line-height: 1.31;
        margin-top: 3px;
      }
  
      & > a {
        text-decoration:none; /* remove phonenumber in safari */
        color:inherit; /* remove phonenumber in safari */
      }
    }
  
    &__angle {
      position: absolute;
      right: 25px;
      color: $white;
      font-size: 30px;
      top: calc(50% - 15px);
    }
  }