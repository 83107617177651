.search-reflex__hit {
  overflow: hidden;
  position: relative;
  padding: 24px 0;

  & ~ .search-reflex__hit {
    border-top: 1px solid var(--sdds-grey-300);
  }

  &:last-of-type {
    border-bottom: 1px solid var(--sdds-grey-300);
    margin-bottom: 24px;
  }

  @include media-breakpoint-down(xs) {
    padding: 24px 0;
  }

  &.news {
    display: flex;
  }

  &-wrapper {
    display: flex;
    flex-direction: row;

    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }
  }

  &-content {
    width: 100%;

    &--wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &-thumbnail {
    @include media-breakpoint-down(xs) {
      display: none;
    }

    &.fallback {
      @include media-breakpoint-down(xs) {
        display: none;
      }
    }
  }

  &-thumbnail {
    width: 200px;
    height: 120px;
    @include object-fit(cover);
    flex-shrink: 0;
    margin-right: 16px;
    border-radius: 4px;
    background-color: $silver;

    @include media-breakpoint-down(xs) {
      display: none;
    }

    &.fallback {
      display: flex;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-down(xs) {
        display: none;
      }

      i {
        color: $dark-grey;
        font-size: 40px;
        text-align: center;
      }
    }
  }

  &-thumbnail--mobile {
    display: none;
    width: 180px;
    height: 110px;
    margin-right: 0;
    margin-left: 10px;
    border-radius: 4px;
    flex-shrink: 0;
    background-color: $silver;
    @include object-fit(cover);

    @include media-breakpoint-down(xs) {
      display: block;
    }

    &.fallback {
      display: none;
      float: right;

      @include media-breakpoint-down(xs) {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      i {
        color: $dark-grey;
        font-size: 40px;
        text-align: center;
      }

    }
  }

  &-icon {
    margin-right: 8px;
    width: 20px;
    height: 20px;

    @include media-breakpoint-down(xs) {
      width: 18px;
      height: 18px;
    }

    i {
      background-color: $white;
      color: $scania-light-grey;
      font-size: 22px;
      width: 25px;
      height: 25px;
      text-align: center;
    }
  }

  &-title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }

  &-title {
    font-family: var(--sdds-font-family-sans);
    color: var(--sdds-blue-500);
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.02em;

    & em {
      font-style: normal;
    }

    &:hover {
      color:var(--sdds-blue-500);
      text-decoration: var(--sdds-link-text-decoration);
      text-decoration-color: var(--sdds-blue-500);
    }

    &:visited {
      color: var(--sdds-blue-500);
      text-decoration-color: var(--sdds-blue-500);
    }
  }

  &-details {
    display: flex;
    margin-top: 5px;
    margin-bottom: 4px;

    &--divider {
      font-size: 12px;
      line-height: 8px;
      color: var(--sdds-grey-700);
      margin-right: 4px;
      margin-left: 4px;
    }
  }

  &-lifecycle,
  &-version,
  &-divider,
  &-contact,
  &-modified {
    font-size: 12px;
    line-height: 8px;
    color: var(--sdds-grey-700);

    &.search-reflex__hit-withdrawn {
      font-weight: bold;
      color: var(--sdds-red-500);
    }
  }

  &-contact {
    &:hover {
      color: var(--sdds-grey-700);
    }

    &:visited {
      color: var(--sdds-grey-700);
    }
  }

  &-description.sdds-detail-03 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: var(--sdds-grey-958);
    letter-spacing: -0.01em;
    margin: 0;

    & em {
      font-style: normal;
      font-weight: bold;
    }
  }
}

