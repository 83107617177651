.header-typeahead {
  form {
    max-width: 490px;
    .form-group {
      margin-bottom: 0;

      .search {
        flex-wrap: nowrap;

        &.top-header__search--open {
          z-index: 1049;
        }

        & .sdds-textfield > input{
          background-color: var(--sdds-grey-50);
          background-size: 20px 20px;
          background-repeat: no-repeat;
          background-position: bottom 50% right 20px;
        }

        & .sdds-textfield > input {
          background-image: url("https://cdn.digitaldesign.scania.com/icons/website_hosted/search.svg");
        }
      }
    }
  }
  &__error {
    @include media-breakpoint-down(sm) {
      height: auto !important;
    }

    & > .text-center {
      margin-bottom: 24px;
    }
  }
  &-results {
    position: absolute;
    overflow: auto;
    margin-top: 0;
    width: 900px;
    max-height: 79vh;
    max-height: calc(var(--vh, 1vh) * 79);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: $white;
    z-index: $zindex-modal;
    padding: 16px 25px 0 ;

    @include media-breakpoint-down(lg) {
      width: 990px;
      margin-left: -200px;
      left: 20%;
    }

    @include media-breakpoint-down(md) {
      position: fixed;
      width: 100%;
      height: auto;
      top: 143px;
      left: 0;
      right: 0;
      margin-left: 0;
    }

    @include media-breakpoint-down(sm) {
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
    }

    &__overlay {
      position: fixed;
      border: none;
      z-index: $zindex-fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      opacity: 0.5;
      visibility: visible;
      background-color: rgb(0, 0, 0);
      transition: opacity 0.075s ease-in-out, visibility 0.075s ease-in-out; /* TODO: mixin? */
      @include media-breakpoint-down(xs) {
        display: none;
      }
      &--hidden {
        opacity: 0;
        visibility: hidden;
      }
    }

    & .sdds-detail-04 {
      font-size: 14px;
      line-height: 14px;
      padding: 0;
      margin-bottom: 8px;
      text-transform: none;
    }
  }

  &-content {
    min-height: 60px;
  }

  &-close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    height: 45px;
    width: 45px;
    padding: 0;
    font-size: 30px;
    color: $medium-grey;
    background-color: transparent;
    border: none;
    z-index: $zindex-popover;
    cursor: pointer;
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }
  &-hits {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    overflow: hidden;
    margin-bottom: 17px;

    p {
      padding: 0 8px;
      font-size: 12px;
      margin-top: 8px;
      margin-bottom: 1px;
    }

    @include media-breakpoint-down(sm) {
      height: auto;
    }
  }

  p.header-typeahead-looking-for {
    margin-bottom: 10px;
    font-size: 16px;
  }

  &-tabs {
    display: flex;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--sdds-grey-500);
    padding-bottom: 20px;
    column-gap: 20px;

    & button {
      appearance: none;
      background-color: $white;
      padding: 10px 15px;
      border: 1px solid var(--sdds-grey-500);
      border-radius: 4px;
      color: $black;

      &.active {
        color: white;
        background-color: var(--sdds-blue-600);
        border-color: var(--sdds-blue-600);
      }
    }
  }

  &-help-link {
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    appearance: none;
    border: none;
    color: $black;
    font-size: 14px;

    &:hover {
      text-decoration: none;
    }

    &:visited {
      color: $black;
    }

    & i {
      height: 20px;
      width: 20px;
      margin-bottom: 5px;
      background-image: url("https://cdn.digitaldesign.scania.com/icons/website_hosted/support.svg");
    }
  }

  &-suggestions {
    list-style-type: none;
    margin: 0;
    padding: 8px 0 0 0;
    width: 100%;

    &__hit {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 8px;
      width: 100%;

      @include media-breakpoint-down(sm) {
        margin-bottom: 12px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:hover & > a {
        text-decoration: underline;
      }

      &--query {
        font-weight: 400;
      }

      & > a {
        width: 100%;
        display: block;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        font-family: var(--sdds-font-family-semi-condensed);
        color: var(--sdds-black);
        letter-spacing: -0.01em;
      }

      & > i {
        font-size: 18px;
        color: var(--sdds-black);
        margin-right: 17px;
      }
    }
  }

  &-link {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 8px 8px 8px 0;

    & .sdds-headline-06 {
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.02em;
      margin-bottom: 4px;
    }

    &__content {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: var(--sdds-grey-700);
      font-size: 10px;
      line-height: 10px;
      font-weight: 400;
    }

    &.sdds-link {
      color: var(--sdds-link);
      font-size: 12px;
    }

    & .sdds-headline-06 {
      color: var(--sdds-link);
    }

    &:hover {
      background-color: var(--sdds-grey-50);
      text-decoration: none;
      cursor: pointer;

      & .sdds-headline-06 {
        text-decoration: underline;
      }
    }
  }

  &-sharepoint {
    max-width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;

    &-hit {
      max-width: 800px;
    }
  }

  &-contact {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 8px;

    &__img {
      width: 35px;
      height: 35px;
      max-width: 35px;
      max-height: 35px;
      @include object-fit(cover);
      border: 1px solid var(--sdds-grey-700);
      border-radius: 50%;
      margin-right: 20px;
      flex-shrink: 0;

      &.fallback-profile {
        display: flex;
        justify-content: center;
        align-items: center;

        span.reflex-sdds-icon {
          background-color: var(--sdds-grey-700);
          width: 16px;
          height: 16px;
        }
      }
    }

    & .fallback {
      i {
        color: var(--sdds-grey-700);
        font-size: 13px;
        width: 35px;
        height: 35px;
        max-width: 35px;
        max-height: 35px;
        border-radius: 50%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__content {
      overflow: hidden;
      width: 100%;
      max-width: 100%;

      & div,
      & a {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &__link{
      display: block;

      &:hover {
        text-decoration: none;

        .header-typeahead-contact__title {
          text-decoration: underline;
        }
      }
    }

    &__title {
      color: var(--sdds-grey-900);
      line-height: 16px;
      font-size: 14px;

      &:hover {
        color: var(--sdds-grey-900);
      }

      &:visited {
        color: var(--sdds-grey-900);
      }
    }

    &__company {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.02em;
      font-weight: 700;
      font-style: normal;
      font-family: var(--sdds-font-family-sans);
      color: var(--sdds-grey-700);
      margin-bottom: 4px;
    }

    &__location {
      color: var(--sdds-grey-700);
      font-size: 10px;
      line-height: 8px;
    }

    &:hover {
      background-color: var(--sdds-grey-50);
      cursor: pointer;

      & .header-typeahead-contact__title {
        text-decoration: underline;
      }
    }
  }

  &__more {
    background-color: var(--sdds-blue-600);
    border-radius: 4px;
    padding: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: var(--sdds-white);
    margin-top: 17px;

    &:hover {
      background-color: var(--sdds-blue-500);
      color: var(--sdds-white); 
      text-decoration: none;
    }

    &:visited {
      color: var(--sdds-white);
    }
  }
}
