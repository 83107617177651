.documents {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
     /* IE10+ CSS styles go here */
     button {
       &:active {
         > * {
           position:relative;
         }
       }
     }
  }
  &-small {
    .documents__sort,
    .documents__item-version,
    .documents__document-contact-wrapper,
    .documents__item-timestamp {
      display: none;
    }

  }
  .fa-spin-fast {
    -webkit-animation: fa-spin 0.5s infinite linear;
    animation: fa-spin 0.5s infinite linear;
  }

  &__title {
    padding: 8px 20px;
    height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $silver;
    color: $scania-black;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: condensed;
    letter-spacing: 0.2px;

    &-placeholder {
      height: 14px;
    }


    a {
      color: $scania-black;
    }
  }

  &__back-button {
    height: 50px;
    border: none;
    color: #fff;
    background-color: $dark-grey;
    text-align: left;
    padding-left: 20px;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:focus, &:active {
      outline: none;
    }

    i {
      margin-right: 15px;
      font-size: 24px;
      width: 20px;
      text-align: center;
    }

    span {
      margin-right: 4px;
    }
  }

  ul {
    padding: 0;
    margin: 0;
  }
  
  &__item {
    list-style-type: none;
    transition: background 0.15s;
    background-color: $scania-white;

    &:first-child {
      margin-top: 0px;
    }

    &-title-wrapper {
      display: flex;
      > * {
        margin-right: 8px;
      }
    }

    &-placeholder {
      height: 54px;
      padding: 17px 20px;
      svg {
        height: 54px;
      }
    }

    &-title {
      color: $scania-blue;
      font-size: 13px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      margin-right: 10px;
      width: calc(100% - 20px);
    }

    &-chips {
      display: flex;
      flex-shrink: 0;
    }

    &-chip {
      font-size: 8px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.4px;
      letter-spacing: normal;
      color: #fff;
      background-color: $medium-grey;
      padding: 7px 8px;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      align-self: flex-start;
      margin-right: 10px;
      line-height: 1;
    }

    &-icon {
      margin-right: 15px;
      width: 20px;
      flex-shrink: 0;
      img {
        width: 20px;
      }
    }

    &-description {
      font-size: 11px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.36;
      letter-spacing: normal;
      color: $scania-black;
      padding-left: 55px;
      padding-right: 20px;
      margin-bottom: 13px;
    }

    &-timestamp {
      color: $scania-black;
      font-size: 11px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      flex-shrink: 0;
      flex-grow: 1;
      text-align: right;
    }
  }

  &__document {
    padding-bottom: 10px;
    border-bottom: 5px solid #fff;

    &-desktop {
      padding: 17px 20px 4px 20px;
      display: flex;
      align-items: center;
    }

    &-mobile {
      padding: 12px 15px;
      display: flex;

      a {
        display: flex;
        color: $scania-blue;
        font-size: 12px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        width: 100%;
      }
    }

    &-bottom-wrapper {
      padding-left: 55px;
    }

    &-size {
      margin-left: 8px;
      font-weight: 400;
      font-size: 100%;
    }

    &-links {
      pointer-events: none;
      transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
      opacity: 0;
      transform: translateY(5px);
      
      &-wrapper {

        max-height: 0px;
        transition: max-height 0.25s ease-in-out;
        overflow: hidden;
      }
      a {

        min-width: 0;
        margin-right: 15px;
      }
    }
    &-contact {
      display: block;
      &-wrapper {
        text-align: right;
        padding-right: 20px;
      }
      &-placeholder {
        height: 10px;
        padding-left: 55px;
        width: 165px;
      }
      &-label {
        font-size: 11px;
        font-family: $font-scania-sans-bold;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        color: $scania-black;
        margin-right: 4px;
      }

      &-link {
        font-size: 11px;
      }

      &:hover {
        text-decoration: none;
        .documents__document-contact-link {
          text-decoration: underline;
        }
      }
    }
    & > a {
      &:hover {
        text-decoration: none;

        & .documents__item-title {
          text-decoration: underline;
          color: $scania-blue;
        }
      }
    }

    @include media-breakpoint-up(sm) {
      &:hover {
        background: $light-beige;

        & > .documents__document-bottom-wrapper .documents__document-links {
            pointer-events: auto;
            opacity: 1;
            transform: translateY(0);
            transition: all 0.5s;
          &-wrapper {
            max-height: 65px;
            transition: max-height 0.25s ease-in-out;
          }
        }
      }
    }
  }

  &__folder {
    display: flex;
    align-items: center;
    border: none;
    background-color: #fff;
    padding: 17px 20px;
    width: 100%;
    cursor: pointer;
    &:hover {
      .documents__item-title {
        text-decoration: underline;
      }
    }
    &:active, &:focus {
      outline: none;
    }
  }

  &__show-more {
    background-color: $silver;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }


  @include media-breakpoint-down(xs) {  

    &__title {
      padding: 10px 15px;

      &-wrapper i {
        margin-right: 10px;
      }
    }

    &__back-button {
      padding-left: 15px;
      i {
        margin-right: 10px;
      }
    }

    &__folder {
      padding: 12px 15px;
    }

    &__item-icon {
      flex-shrink: 0;
      margin-right: 10px;
    }

    &__item-title {
      flex-grow: 1;
    }

    &__item-chip:last-child {
      margin-right: 0;
    }

    &__item-description {
      display: none;
    }

    &__item-placeholder {
      height: 44px;
      padding-top: 12px;
      padding-left: 15px;
    }
    
    &__document-top-wrapper {
      padding: 12px 15px 4px 15px;
    }

    &__document-bottom-wrapper {
      padding-left: 45px;
      display: block;
    }

    &__document-links {
      display: none;
    }
    

    &__document-contact-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 15px;
    }

    &__document-contact,
    &__item-timestamp,
    &__sort{
      display: none;
    }

    &__document-placeholder {
      padding-left: 45px;
    }

    &__document-wrapper-inner {
      width: 100%;
    }

    &__show-more {
      padding: 0 15px;
      a {
        width: 100%;
      }
    }
  }
}

/* TODO: move to bootstrap */
.dropdown-small {
  border-radius: 2px;

  .btn.btn-secondary, .dropdown-item {
    height: 25px;
    border: none;
    padding-right:12px;
    padding-left: 12px;
    font-size: 11px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform: none;
    cursor: pointer;
    &:hover, &:active, &:focus {
      background-color: #fff;
      color: $scania-black;
      outline: none;
    }


  }

  &__toggle.btn.btn-secondary {
    border: 1px solid $scania-light-grey;
    transition: none;
    justify-content: space-between;
    i {
      @include fontAwesome(angle-down);
      color: $scania-black;
      margin-left: 12px;
      margin-right: 0;
    }
    &:hover {
      i {
        color: $scania-black;
      }
    }
  }

  
  &.show {
    .dropdown-small__toggle.btn.btn-secondary {
      background: $dark-grey;
      border-color: $dark-grey;
      border-radius: 0;
      color: #fff;
      &:hover, &:active, &:focus {
        color: #fff;
        background: $dark-grey;
      }

      i {
        @include fontAwesome(angle-up);
        color: #fff;
      }
    }
  }

  .dropdown-menu {
    border: none;
    top: -2px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    min-width: 0;
  }

  .dropdown-item {
    height: 34px;
    font-size: 11px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.27;
    letter-spacing: normal;
    border-bottom: 1px solid $silver;
    &:last-child {
      border-bottom: none;
    }
  }
}