.popup-form {

  &__target-info {
    margin-bottom: 12px;
  }

  &__field-info {
    font-size: 12px;
    margin-bottom: 8px;
  }

}